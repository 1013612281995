import React from 'react';
import { Checkbox } from '../../components';
import { AnswerBlock, ChoiceLabel, CustomValidationBlock, ErrorContainer } from './Style';
import FormGenerator from '../../pages/Form/FormGenerator';

const CheckboxGroup = ({ group, data = {}, onChange, label, disabled = false, members = [], questionType, formJson }) => {

    return (
        <CustomValidationBlock>
            {group.map((choice, index) => (
                <AnswerBlock key={index}>
                    <ChoiceLabel className={disabled ? '' : 'cursor-pointer'} htmlFor={choice.key} checked={!!data[choice.key]}>
                        <Checkbox
                            type='checkbox'
                            variant='white'
                            id={choice.key}
                            name={label.replace(/\s/g, '')}
                            checked={!!data[choice.key]}
                            disabled={disabled}
                            onChange={() => onChange(choice.key, !data[choice.key])}
                        />
                        {choice.value}
                    </ChoiceLabel>
                    {choice.question_blocks?.map((q_blocks, idx) => (
                        <React.Fragment key={idx}>
                            {q_blocks?.question_block?.map((q, index) => (
                                data[choice.key] === true && (
                                    <ul key={index}>
                                        <FormGenerator
                                            members={members}
                                            data={data}
                                            question={q.question}
                                            onFormDataChange={onChange}
                                            disabled={disabled}
                                            formJson={formJson}
                                            onChangeOfQuestion={() => onChange(q)}
                                            questionType={questionType}
                                        />
                                    </ul>
                                )
                            ))}
                        </React.Fragment>
                    ))}
                </AnswerBlock>
            ))}
            <input className='hidden_input' type="text" value={group.length > 1 ? (!group.some((r) => data[r.key]) ? '' : 'temp_val') : 'temp_val'} name={label.replace(/\s/g, '')} id="" required onChange={() => { }} />
            <ErrorContainer type="invalid">Select at least one option.</ErrorContainer>
        </CustomValidationBlock>
    );
};

export default CheckboxGroup;
