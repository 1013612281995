import React, { useState } from 'react';
import Select from 'react-select';
import { DropDownStyle } from '../../helpers';
import { AnswerBlock, CustomValidationBlock, ErrorContainer } from './Style';

const createTimeOptions = () => {
    const options = [];
    // Create options for each hour and each 5-minute interval
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 1) {
            const formattedHour = (hour % 12 || 12).toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const period = hour < 12 ? 'AM' : 'PM';
            const timeString = `${formattedHour}:${formattedMinute} ${period}`;
            options.push({
                value: timeString,
                label: timeString,
                sortValue: hour * 60 + minute // Use this for sorting
            });
        }
    }
    // Sort the options based on the sortValue
    options.sort((a, b) => a.sortValue - b.sortValue);
    return options;
};

const TimePickerExample = ({ answerKey, data, onChange, required, disabled }) => {
    console.log("🚀 ~ TimePickerExample ~ answerKey:", answerKey)
    const timeOptions = createTimeOptions();

    return (
        <AnswerBlock className='col-lg-12'>
            <CustomValidationBlock>
                <Select
                    options={timeOptions}
                    value={data[answerKey] ? timeOptions.find(answer => data[answerKey] === answer.value) : null}
                    onChange={(e) => onChange(answerKey, e.value)}
                    placeholder="Select Time"
                    isMulti={false}
                    menuPosition="fixed"
                    styles={DropDownStyle}
                    required={required}
                    isDisabled={disabled}
                />
                <input className='hidden_input' type="text" value={data[answerKey] ? timeOptions.find(answer => data[answerKey] === answer.value) : null} name={answerKey} id="" required={required} onChange={() => { }} />
                <ErrorContainer type="invalid">Required</ErrorContainer>
            </CustomValidationBlock>
        </AnswerBlock>
    );
};

export default TimePickerExample;