import React, { Component } from 'react';
import { Col, Figure, Spinner, Container, Alert, Row } from 'react-bootstrap';
import axios from 'axios';
import styled from 'styled-components';

import { SearchInput, CustomCard, CardBody, IconContainer, ImageContainer } from '../Administration/Style';
import ViolenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import ExposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import SharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import ChemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import SharpsDisposalEvaluationForm from '../../assets/forms-icon/eval_sharps_disposal.svg';
import HepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import SharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import SafetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import QuarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import AnnualTBRiskAssessmentForm from '../../assets/forms-icon/tbRiskAssessment.svg';
import UploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';

import { HealthRecordDetails } from '../../helpers';
import { ContentHeader, FormContainer, FormListContainer, SrNoCircle } from './Style';
import { connect } from 'react-redux';

const icons = { ViolenceIncidentForm, SharpsDisposalContainerEvaluationForm, ExposureIncidentForm, SharpsInjuryForm, ChemicalExposureForm, SharpsDisposalEvaluationForm, HepatitisBVaccineConsentForm, ECRIsNeedleStickPreventionDeviceEvaluationForm, SafetyDentalSyringesForm, SlipTripandFallForm, QuarterlySafetyReportForm, NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom, AnnualTBRiskAssessmentForm, UploadHealthDocumentForm };


const Description = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class FormsList extends Component {
    state = {
        formsList: [],
        healthDocList: [HealthRecordDetails],
        loading: false,
        errors: { apiError: false },
        search: '',
        isAdmin: false,
    };

    componentDidMount() {
        this.getFormsList();
        const { user } = this.props;
        if (user && user.isAdmin) {
            this.setState({ isAdmin: true });
        } else {
            this.setState({ isAdmin: false });
        }
    };

    getFormsList = async () => {
        try {
            this.setState({ loading: true });
            const { user } = this.props;
            const locationId = user.locationIds[user.currentLocationIndex];
            const { data: { payload: formsList } } = await axios.get('/api/v2/forms?locationId=' + locationId);
            this.setState({ loading: false, formsList });
        } catch (error) {
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
        }
    };

    onSelect = (formId) => {
        const { history } = this.props;
        history.push('/forms/view?formId=' + formId);
    };

    render() {
        const { search, formsList, healthDocList, errors: { apiError, errorMessage }, loading, isAdmin } = this.state;
        const formListTemp = search ? formsList.filter((form) => form.name.toLowerCase().includes(search.toLowerCase())) : formsList;
        const healthDocListTemp = search ? healthDocList.filter((form) => form.name.toLowerCase().includes(search.toLowerCase())) : healthDocList;
        return (
            <FormListContainer>
                <div className="formHeader">
                    <h2 className='my-0'>Forms</h2>
                    <p className='my-0'>Select a form you are creating</p>
                </div>
                <SearchInput onChange={(e) => { this.setState({ search: e.target.value }) }} value={search} type='text' className='pl-5 col-lg-3 col-md-6 col-sm-12' placeholder='Search...' />
                {loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    apiError && !formsList.length ?
                        <FormContainer>
                            <Col>
                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                    <p className='w-100 mb-0'>{errorMessage}</p>
                                </Alert>
                            </Col>
                        </FormContainer>
                        :
                        <FormContainer>
                            {
                                formListTemp.map((section) => (
                                    <div key={section._id} >
                                        <ContentHeader>
                                            <h4 className='p-0 m-0'>{section.name}</h4>
                                            <SrNoCircle>{section.forms.length}</SrNoCircle>
                                        </ContentHeader>
                                        <Row className="mx-0">
                                            {section.forms
                                                .filter((form) => isAdmin || !form.viewOnlyForAdmin)
                                                .map((form) => (
                                                    <Col key={form.id} xs={12} md={6} className="mb-4">
                                                        <CustomCard onClick={() => this.onSelect(form.id)}>
                                                            <CardBody>
                                                                <IconContainer>
                                                                    <ImageContainer
                                                                        width="56"
                                                                        height="56"
                                                                        src={icons[form.icon_id]}
                                                                    />
                                                                    <Figure.Caption>
                                                                        <div className="text-success">
                                                                            <h5>{form.name}</h5>
                                                                        </div>
                                                                        <Description>{form.description}</Description>
                                                                    </Figure.Caption>
                                                                </IconContainer>
                                                            </CardBody>
                                                        </CustomCard>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                ))
                            }
                            <ContentHeader className='mt-0 pb-0'>Health Document ({healthDocListTemp.length})</ContentHeader>
                            {healthDocListTemp.map((healthDoc) => (
                                <Col key={healthDoc.id} xs={12} md={6} className="mb-4">
                                    <CustomCard onClick={(e) => this.onSelect(healthDoc.id)}>
                                        <CardBody>
                                            <IconContainer>
                                                <ImageContainer
                                                    width='56'
                                                    height='56'
                                                    src={icons[healthDoc.icon_id]}
                                                />
                                                <Figure.Caption>
                                                    <div className="text-success">
                                                        <h5>{healthDoc.name}</h5>
                                                    </div>
                                                    {healthDoc.description}
                                                </Figure.Caption>
                                            </IconContainer>
                                        </CardBody>
                                    </CustomCard>
                                </Col>
                            ))}
                        </FormContainer>
                }
            </FormListContainer>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user.currentUser
});
// export default FormsList;
export default connect(mapStateToProps)(FormsList);