import React from 'react';
import { Checkbox } from '../../components';
import { AnswerBlock, CustomValidationBlock, ErrorContainer, ImageContainer } from './Style';
import FormGenerator from '../../pages/Form/FormGenerator';
import { Col, Figure, Row } from 'react-bootstrap';
import { CustomCard, IconContainer } from '../../pages/Dashboard/Style';
import ViolenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import ExposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import SharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import ChemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import SharpsDisposalEvaluationForm from '../../assets/forms-icon/eval_sharps_disposal.svg';
import HepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import SharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import SafetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import QuarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import AnnualTBRiskAssessmentForm from '../../assets/forms-icon/tbRiskAssessment.svg';
import UploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';
import styled from 'styled-components';

const icons = {
    ViolenceIncidentForm,
    SharpsDisposalContainerEvaluationForm,
    ExposureIncidentForm,
    SharpsInjuryForm,
    ChemicalExposureForm,
    SharpsDisposalEvaluationForm,
    HepatitisBVaccineConsentForm,
    ECRIsNeedleStickPreventionDeviceEvaluationForm,
    SafetyDentalSyringesForm,
    SlipTripandFallForm,
    QuarterlySafetyReportForm,
    NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom,
    AnnualTBRiskAssessmentForm,
    UploadHealthDocumentForm
};

const ChoiceLabel = styled.label`
    font-size: 16px;
    color: ${(props) => (props.checked ? '#ffffff' : '#676767')};
    background-color: ${(props) => (props.checked ? '#42A764' : '#ffffff')}; 
    padding: 0.4rem 0.6rem;
    border-radius: 6px;
    display: block;
`;

const CheckboxForConsentAndDeclination = ({ group, data = {}, onChange, label, disabled = false, members = [], questionType, formJson }) => {
    console.log("🚀 ~ CheckboxForConsentAndDeclination ~ formJson:", formJson)
    const groupedChoices = group.reduce((acc, choice) => {
        const section = choice.checkboxSection || 'Other';
        if (!acc[section]) acc[section] = [];
        acc[section].push(choice);
        return acc;
    }, {});
    console.log("🚀 ~ groupedChoices ~ groupedChoices:", groupedChoices)
    return (
        <CustomValidationBlock>
            {Object.entries(groupedChoices).map(([section, choices]) => (
                <div key={section}>
                    <h4 className="mt-3 mb-2 p-3" style={{ backgroundColor: '#F3F9F5', fontSize: '15px' }}>{section}</h4>
                    {choices.map((choice, index) => (
                        <AnswerBlock key={index}>
                            <Row className='mx-0'>
                                <Col key={index} xs={6} md={12} className="mb-4">
                                    <CustomCard>
                                        <ChoiceLabel className={disabled ? 'd-flex' : 'cursor-pointer d-flex'} htmlFor={choice.key} checked={!!data[choice.key]}>
                                            <div className='m-2'>
                                                <IconContainer className='align-items-center'>
                                                    <ImageContainer
                                                        width='56'
                                                        height='56'
                                                        src={icons[choice.icon_id]}
                                                        className='m-2'
                                                    />
                                                    <Figure.Caption className={!!data[choice.key] ? 'text-white' : ''}>
                                                        <div className={!!data[choice.key] ? 'text-white' : 'text-success'}>
                                                            <h5>{choice.value}</h5>
                                                        </div>
                                                        {choice.description}
                                                    </Figure.Caption>
                                                    <Checkbox
                                                        type='checkbox'
                                                        variant='white'
                                                        id={choice.key}
                                                        name={label.replace(/\s/g, '')}
                                                        checked={!!data[choice.key]}
                                                        disabled={disabled}
                                                        onChange={() => onChange(choice.key, !data[choice.key])}
                                                    />
                                                </IconContainer>
                                            </div>
                                        </ChoiceLabel>
                                    </CustomCard>
                                </Col>
                            </Row>
                            {choice.question_blocks?.map((q_blocks, idx) => (
                                <React.Fragment key={idx}>
                                    {q_blocks?.question_block?.map((q, index) => (
                                        data[choice.key] === true && (
                                            <ul key={index}>
                                                <FormGenerator
                                                    members={members}
                                                    data={data}
                                                    question={q.question}
                                                    onFormDataChange={onChange}
                                                    disabled={disabled}
                                                    formJson={formJson}
                                                    onChangeOfQuestion={() => onChange(q)}
                                                    questionType={questionType}
                                                />
                                            </ul>
                                        )
                                    ))}
                                </React.Fragment>
                            ))}
                        </AnswerBlock>
                    ))}
                </div>
            ))}
            <input className='hidden_input' type="text" value={group.length > 1 ? (!group.some((r) => data[r.key]) ? '' : 'temp_val') : 'temp_val'} name={label.replace(/\s/g, '')} id="" required onChange={() => { }} />
            <ErrorContainer type="invalid">Select at least one option.</ErrorContainer>
        </CustomValidationBlock>
    );
};
export default CheckboxForConsentAndDeclination;