import { useEffect, useState } from "react";
import { Container, Modal, Spinner } from "react-bootstrap"
import closeIcon from '../../assets/images/glyph-close-gray.svg';
import axios from "axios";
import { DownloadLinkUrl } from "./Style";


const ViewUploadedDocs = ({ file, closeView, fileOperation, fetchFileFrom = 'records' }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fileOperation === 'download' ? downloadFile() : viewFile();
    }, []);

    const viewFile = async () => {
        try {
            setShowModal(true);
            if (file && file.path) {
                setLoading(true);
                file.type = file.contentType;
                const response = await axios.get(`/api/v2/view_record_file/${btoa(file.path)}/${fetchFileFrom}`, { responseType: 'blob' });
                const blob = new Blob([response.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(blob);
                console.log("🚀 ~ viewFile ~ fileURL:", fileURL)
                setImageUrl(fileURL);
                setLoading(false);
            } else {
                setLoading(true);
                const reader = new FileReader();
                reader.onload = () => {
                    setImageUrl(reader.result);
                };
                reader.readAsDataURL(file);
                setLoading(false);
            };
        } catch (error) {
            console.error('Error fetching file:', error);
        }
    };

    const downloadFile = async () => {
        try {
            const response = await axios.get(`/api/v2/view_record_file/${btoa(file.path)}/${fetchFileFrom}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            size='xl'
            show={showModal}>
            <Modal.Header>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between">
                        <span>Upload health document for </span>
                        <span onClick={closeView} className="mr-2 cursor-pointer">
                            <img src={closeIcon} alt="" />
                        </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    <Container>
                        {
                            ['application/pdf', 'image/png', 'image/jpeg'].includes(file.type) ? (
                                file.type === 'application/pdf' ?
                                    <iframe src={imageUrl} frameBorder="0" className="w-100" style={{ height: '75vh' }} />
                                    :
                                    <img src={imageUrl} style={{ width: '100%' }} />
                            ) : (
                                <DownloadLinkUrl className='d-flex justify-content-between'>
                                    <p>
                                        The uploaded file is not a supported format to view. Please download it and open it in an appropriate viewer.
                                    </p>
                                    <span className="download_url" onClick={downloadFile}>
                                        {file.name}
                                    </span>
                                </DownloadLinkUrl>
                            )
                        }
                    </Container>
                }
            </Modal.Body>
        </Modal>
    )
};

export default ViewUploadedDocs;