import axios from 'axios';
import { apiUrl } from '../../helpers';

const getExpression = async (filter, callback) => {
    try {
        if (!filter?.length) {
            console.log('Invalid Expression Format');
            callback();
        }

        const [expression_name, ...parameters] = filter;

        const payload = { expression_name, parameters }

        const response = await axios.post(apiUrl('/api/v2/master/resolveExpression'), payload);

        const data = response.data.payload;

        if (data && data.error) {
            console.log(`Error: ${data.error}`);
        }
        callback(data.value);

    } catch (error) {
        console.error("Error fetching Expression: ", filter[0]);
        callback();
    }
};

export default getExpression;
