import { useState } from "react";
import FormGenerator from "../../pages/Form/FormGenerator";
import { AnswerBlock, Label } from "./Style";
import { Button } from "react-bootstrap";
import addIcon from '../../assets/images/add-green.svg';

const ArrayButton = ({
    group: question_blocks,
    data = {},
    onChange,
    onFormChange,
    disabled = false,
    members = [],
    label,
    blockLabel,
    blockKey,
    equipments = [],
    formJson = {},
}) => {
    const [questionBlocks, setQuestionBlocks] = useState(question_blocks);
    const handleAddBlock = () => {
        let newBlock = JSON.parse(JSON.stringify(question_blocks[0]));
        setQuestionBlocks(prevBlocks => [...prevBlocks, newBlock]);
        onFormChange([...questionBlocks, newBlock]);
    };
    return (
        <>
            {
                question_blocks &&
                question_blocks.map((q_blocks, index) => (
                    <div key={index}>
                        {
                            blockLabel &&
                            <Label className="mt-3">
                                <Label className='d-block' dangerouslySetInnerHTML={{ __html: `${blockLabel} ${index + 1}` }} />
                            </Label>
                        }

                        <AnswerBlock>
                            {q_blocks &&
                                q_blocks.question_block &&
                                q_blocks.question_block.map((q, qIndex) => (
                                    <FormGenerator
                                        members={members}
                                        data={data}
                                        equipments={equipments}
                                        key={`${index}-${qIndex}`}
                                        question={q.question}
                                        formJson={formJson}
                                        onFormDataChange={onChange}
                                        disabled={disabled}
                                        onFormChange={onFormChange}
                                        onChangeOfQuestion={() => onChange(q)}
                                        questionType={{ type: 'button_to_add_fields_repeatedly', blockIndex: index, qIndex: qIndex, blockKey }}
                                    />
                                ))}
                        </AnswerBlock>
                    </div>
                ))}
            {disabled === false && <Button type="button" className='col-lg-12' variant="outline-success" onClick={handleAddBlock}>
                <img src={addIcon} alt="Add Icon" />{label}</Button>}
        </>
    )
};

export default ArrayButton;