import React, { memo } from "react";
import { surveyThemes } from "./survey-theme";
import { env_survey_variables } from "./survey-locale";
import { FunctionFactory } from "survey-core";
import getExpression from "./SurveyService";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/survey-core.min.css";

function resolveExpression(filter) {
    return getExpression(filter, this.returnResult);
}

FunctionFactory.Instance.register("resolveExpression", resolveExpression, true);

const SurveyComponent = memo(({ data, themeName, onComplete }) => {

    const { form, record } = data || {};
    let survey = new Model(form.json);

    if (form && !record) {
        if (env_survey_variables && typeof env_survey_variables === 'object') {
            Object.keys(env_survey_variables).forEach((variable) => {
                survey.setVariable(variable, env_survey_variables[variable]);
            });
        }
    }

    if (record) {
        survey.data = JSON.parse(record?.formData);
        survey.mode = "display";

        if (record.variables) {
            Object.keys(record.variables).forEach((variable) => {
                survey.setVariable(variable, record.variables[variable]);
            });
        }

    }

    survey.applyTheme(surveyThemes);

    survey.onComplete.add((sender, options) => {
        const variable_arr = survey.getVariableNames();
        const variables = {};
        if (variable_arr && variable_arr.length) {
            variable_arr.map((key) => {
                variables[key] = survey.getVariable(key);
            })
        }
        options.variables = variables;
        onComplete(sender, options);
    });

    return (
        <div>
            <Survey model={survey} />
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        JSON.stringify(prevProps.data) == JSON.stringify(nextProps.data)
    );
});

export default SurveyComponent;