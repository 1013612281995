import { useState, useRef } from "react";
import { DownloadLinkUrl, SubQuestionLabel } from "./Style";
import ViewUploadedDocs from "./ViewUploadedDocs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";

const InfoLinkBlock = ({ label, members, formJson, filePath = {}, data, reportOperation }) => {
    console.log("🚀 ~ InfoLinkBlock ~ formJson:", formJson)
    console.log("🚀 ~ InfoLinkBlock ~ members:", members)

    console.log("🚀 ~ InfoLinkBlock ~ data:", data)
    const [showModal, setShowModal] = useState(false);
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [imageUrl, setImageUrl] = useState({});
    const [loading, setLoading] = useState(false);
    const reportRef = useRef(null);
    const closeView = () => {
        setShowModal(false);
    };

    const htmlToText = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
    };

    const generatePDF = () => {
        if (!reportRef.current) return;

        // Temporarily make it visible
        reportRef.current.style.position = "absolute";
        reportRef.current.style.left = "0px";
        reportRef.current.style.visibility = "visible";

        html2canvas(reportRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
            pdf.save("Report.pdf");

            // Hide it again after PDF generation
            reportRef.current.style.position = "absolute";
            reportRef.current.style.left = "-9999px";
            reportRef.current.style.visibility = "hidden";
        });
    };
    const generatePDFBlob = async () => {
        if (!reportRef.current) return null;

        // Temporarily make it visible
        reportRef.current.style.position = "absolute";
        reportRef.current.style.left = "0px";
        reportRef.current.style.visibility = "visible";

        return html2canvas(reportRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);

            // Convert to Blob
            const pdfBlob = pdf.output("blob");

            // Hide it again after PDF generation
            reportRef.current.style.position = "absolute";
            reportRef.current.style.left = "-9999px";
            reportRef.current.style.visibility = "hidden";

            return pdfBlob;
        });
    };
    const viewFile = async () => {
        try {
            setShowPDFModal(true);
            setLoading(true);

            // Generate PDF from reportRef
            const pdfBlob = await generatePDFBlob();
            console.log("🚀 ~ viewFile ~ pdfBlob:", pdfBlob)

            if (pdfBlob) {
                const fileURL = URL.createObjectURL(pdfBlob);
                console.log("🚀 ~ viewFile ~ fileURL:", fileURL);
                setImageUrl(fileURL);
            }

            setLoading(false);
        } catch (error) {
            console.error("Error generating file:", error);
            setLoading(false);
        }
    };
    return (
        <DownloadLinkUrl className='col-lg-12'>
            {
                filePath && filePath.path ?
                    <span onClick={() => { setShowModal(true); }} className='cursor-pointer'>
                        <SubQuestionLabel className='d-block' dangerouslySetInnerHTML={{ __html: label }} />
                    </span>
                    :
                    reportOperation === 'download' ?
                        <span onClick={() => generatePDF()} className='cursor-pointer'>
                            <SubQuestionLabel className='d-block' dangerouslySetInnerHTML={{ __html: label }} />
                        </span>
                        : reportOperation === 'view' ?
                            <span onClick={() => viewFile()} className='cursor-pointer'>
                                <SubQuestionLabel className='d-block' dangerouslySetInnerHTML={{ __html: label }} />
                            </span>
                            : <SubQuestionLabel className='d-block' dangerouslySetInnerHTML={{ __html: label }} />
            }
            {
                showModal && <ViewUploadedDocs file={filePath} closeView={closeView} fileOperation={filePath.fileOperation} fetchFileFrom={'forms'} />
            }

            {showPDFModal && (
                <div className="modal">
                    {imageUrl ? (
                        <>
                            <iframe src={imageUrl} frameBorder="0" className="w-100" style={{ height: '75vh' }} />
                            <button onClick={() => setShowPDFModal(false)}>Close</button>
                        </>
                    ) : (
                        <p>Loading PDF...</p>
                    )}
                </div>
            )}


            {/* Report Content */}
            <div ref={reportRef} className="p-3 border" style={{ position: "absolute", left: "-9999px", visibility: "hidden" }}>
                <h2>{formJson.formName + ' Report'}</h2>
                {formJson.question_blocks.map((step, index) => (
                    <div key={step.stepNumber + index} className="mb-4 mt-4">
                        {formJson.sections[index].label ?
                            <h3 style={{ backgroundColor: "#f2f2f2", padding: "10px", borderRadius: "5px" }}>
                                {step.stepNumber}. {formJson.sections[index].label}
                            </h3>
                            :
                            ''
                        }
                        <ul>
                            {step.question_block.map((block) => {
                                const questionLabel = htmlToText(block.question.label);
                                let answerValue = '';
                                if (block.question.type === "employee-select") {
                                    members.filter((member) => {
                                        if (member._id === data[block.question.answer?.key]) {
                                            answerValue = member.fullName
                                        }
                                    })
                                } else if (block.question.type === "checkbox") {
                                    block.question.group.forEach((d) => {
                                        if (d.key in data) {
                                            answerValue += d.value + ', ';
                                        }
                                    })
                                    console.log("🚀 ~ block.question.group.forEach ~ answerValue:", answerValue)
                                } else {
                                    answerValue = block.question.answer ? data[block.question.answer?.key] : !block.question.answer ? '' : "No answer provided";

                                }

                                return (
                                    <li key={block.question?.answer?.key} className="mb-2">
                                        <strong>{questionLabel}</strong> {answerValue ? ':' + answerValue : ''}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </div>

        </DownloadLinkUrl>
    )
};

export default InfoLinkBlock;