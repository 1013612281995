

import React from 'react';
import { AnswerBlock } from './Style';
import FormGenerator from '../../pages/Form/FormGenerator';

const SubQuestion = ({ group: question_blocks, answer = {}, data = {}, onChange, required = false, disabled = false, members = [], questionType, formJson }) => (
    <AnswerBlock>
        {
            question_blocks &&
            question_blocks.map((q_blocks, index) => (
                <div key={index}>
                    {
                        q_blocks &&
                        q_blocks.question_block &&
                        q_blocks.question_block[index].question &&
                        q_blocks.question_block.map((q, index) => (
                            <FormGenerator
                                members={members}
                                data={data}
                                key={index}
                                question={q.question}
                                onFormDataChange={onChange}
                                formJson={formJson}
                                disabled={disabled}
                                questionType={questionType}
                                onChangeOfQuestion={() => onChange(q)}
                            />
                        ))}
                </div>
            ))
        }
    </AnswerBlock>
);

export default SubQuestion;
