import React, { memo } from 'react';
import { Label, SrNoCircle, SubQuestionLabel } from './Style';
import { DateInput, EmployeeSelect, FileSelect, InfoLinkBlock, InfoTextField, InputField, RadioButtonGroup, SubQuestion, TextareaInput, TimePickerExample, CertifyCheckbox, ArrayButton, CheckboxGroup } from '../../components';
import OnlyQuestion from '../../components/Form/OnlyQuestion';
import PpeSelect from '../../components/Form/PpeSelect';
import HelpTextWithIcon from '../../components/Form/HelpTextWithIcon';
import CheckboxForConsentAndDeclination from '../../components/Form/CheckboxForConsentAndDeclination';

const Element = ({ questionNo, label, type, exampleLabel }) => (
    <div>
        {
            questionNo ?
                <Label>
                    <SrNoCircle>
                        {questionNo}
                    </SrNoCircle>
                    <Label className={!type ? 'd-block' : ''} dangerouslySetInnerHTML={{ __html: label }} />
                </Label>
                :
                <SubQuestionLabel dangerouslySetInnerHTML={{ __html: label }} />
        }
        {
            exampleLabel &&
            <div className="row border-left border-success m-2 ml-5">
                <div className='col-12 p-2'>
                    <span dangerouslySetInnerHTML={{ __html: exampleLabel }} />
                </div>
            </div>
        }
    </div>
);

const FormGenerator = memo(({ data, question, questionNo, onFormDataChange, members, equipments, disabled, onChangeOfQuestion = () => { }, questionType = {}, formJson = {} }) => {
    const { answer, type, filePath, reportOperation, label, exampleLabel, answers = {}, question_blocks, group, required, selectUptoToday, buttonLabel = '', blockLabel = '' } = question;
    const onFormChange = (data) => {
        question.question = data;
        question.question_blocks = data;
        onChangeOfQuestion();
    };
    switch (type) {
        case 'input':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <InputField
                        answerKey={answer.key}
                        value={questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key] ?
                            questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key]
                            : data?.[answer?.key] || ''
                        }
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'radio':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <RadioButtonGroup
                        group={group}
                        answer={answer}
                        members={members}
                        equipments={equipments}
                        data={data}
                        formJson={formJson}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'checkbox':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <CheckboxGroup
                        group={group}
                        data={data}
                        onChange={onFormDataChange}
                        members={members}
                        formJson={formJson}
                        label={label}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'checkbox_for_consent_and_declination':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <CheckboxForConsentAndDeclination
                        group={group}
                        data={data}
                        onChange={onFormDataChange}
                        members={members}
                        formJson={formJson}
                        label={label}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'time':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <TimePickerExample
                        group={group}
                        data={data}
                        answerKey={answer.key}
                        onChange={onFormDataChange}
                        members={members}
                        label={label}
                        required={required}
                        disabled={disabled}
                    />
                </>
            );
        case 'textarea':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <TextareaInput
                        answerKey={answer.key}
                        value={questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key] ?
                            questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key]
                            : data?.[answer?.key] || ''
                        }
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'employee-select':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <EmployeeSelect
                        answerKey={answer.key}
                        selectedValue={data[answer.key]}
                        onChange={onFormDataChange}
                        options={members}
                        isMulti={question.isMulti}
                        required={required}
                        disabled={disabled}
                    />
                </>
            );
        case 'selectWithMetaData':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <EmployeeSelect
                        answerKey={answer.key}
                        selectedValue={data[answer.key]}
                        onChange={onFormDataChange}
                        options={question.optionForSelect}
                        isMulti={question.isMulti}
                        required={required}
                        disabled={disabled}
                    />
                </>
            );
        case 'date':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <DateInput
                        answerKey={answer.key}
                        value={data[answer.key]}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        selectUptoToday={selectUptoToday}
                    />
                </>
            );
        case 'file':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <FileSelect
                        answerKey={answer.key}
                        question={question}
                        formJson={formJson}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        selectUptoToday={selectUptoToday}
                        questionType={questionType}
                    />
                </>
            );
        case 'sub_question':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <SubQuestion
                        group={question_blocks}
                        answer={answer}
                        members={members}
                        formJson={formJson}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'certify_checkbox':
            return (
                <>
                    <CertifyCheckbox
                        label={label}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                    />
                </>
            );
        case 'button_to_add_fields_repeatedly':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <ArrayButton
                        label={buttonLabel}
                        blockLabel={blockLabel}
                        group={question.question_blocks}
                        answer={answer}
                        members={members}
                        formJson={formJson}
                        equipments={equipments}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        onFormChange={onFormChange}
                        blockKey={answers.key}
                    />
                </>
            );
        case 'help_text':
            return <Element label={label} exampleLabel={exampleLabel} />;
        case 'contentful_text':
            return <Element label={label} />;
        case 'info_url':
            return (<InfoLinkBlock type={type} formJson={formJson} members={members} data={data} label={label} filePath={filePath} reportOperation={reportOperation} />);
        case 'info_text':
            return <InfoTextField type={type} label={label} />;
        case 'only_question_without_background_color':
            return (
                <>
                    <OnlyQuestion
                        label={label}
                        group={question_blocks}
                        answer={answer}
                        formJson={formJson}
                        members={members}
                        equipments={equipments}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'help_text_with_icon':
            return (
                <>
                    <HelpTextWithIcon
                        label={label}
                        group={question_blocks}
                        answer={answer}
                        members={members}
                        formJson={formJson}
                        equipments={equipments}
                        data={data}
                        onChange={onFormDataChange}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        case 'ppe_select':
            return (
                <>
                    <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />
                    <PpeSelect
                        answerKey={answer.key}
                        selectedValue={questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key] ?
                            questionType && data[questionType.blockKey] && data[questionType.blockKey][questionType.blockIndex] && data[questionType.blockKey][questionType.blockIndex][answer.key]
                            : data?.[answer?.key] || ''
                        }
                        onChange={onFormDataChange}
                        options={equipments}
                        members={members}
                        isMulti={question.isMulti}
                        required={required}
                        disabled={disabled}
                        questionType={questionType}
                    />
                </>
            );
        default:
            return <Element type={type} label={label} questionNo={questionNo} exampleLabel={exampleLabel} />;
    }
});

export default FormGenerator;
