import { AnswerBlock } from "./Style";
import FormGenerator from "../../pages/Form/FormGenerator";


const HelpTextWithIcon = ({ label, formJson, group: question_blocks, answer = {}, data = {}, onChange, required = false, disabled = false, members = [], equipments = [], questionType }) => (
    <>
        <div className="row border-left border-success ml-4">
            <div className="col-1 text-center mt-2" style={{ alignSelf: 'center' }}>
                <img src={infoTextIcon} alt="Open FIle" />
            </div>
            <div className='col-11 p-2'>

                <b dangerouslySetInnerHTML={{ __html: label }} />
            </div>
        </div>

        <AnswerBlock>
            {
                question_blocks &&
                question_blocks.map((q_blocks, index) => (
                    <div key={index}>
                        {
                            q_blocks &&
                            q_blocks.question_block &&
                            q_blocks.question_block[index].question &&
                            q_blocks.question_block.map((q, index) => (
                                <FormGenerator
                                    members={members}
                                    data={data}
                                    key={index}
                                    equipments={equipments}
                                    question={q.question}
                                    onFormDataChange={onChange}
                                    disabled={disabled}
                                    formJson={formJson}
                                    questionNo={index + 1}
                                    questionType={questionType}
                                    onChangeOfQuestion={() => onChange(q)}
                                />
                            ))}
                    </div>
                ))
            }
        </AnswerBlock>
    </>
);

export default HelpTextWithIcon;